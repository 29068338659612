<template>
    <div id="high-graduate-liquid-indicator-svg">
        <svg :style="'height:' + size + 'px'" height="206" viewBox="0 0 30 206" xmlns="http://www.w3.org/2000/svg">
            <line stroke="LightGrey" stroke-width="3" x1="11" x2="27" y1="5" y2="5" />

            <line stroke="LightGrey" stroke-width="10" x1="3" x2="3" y1="4" y2="205" />

            <line stroke="red" stroke-width="10" x1="3" x2="3" y1="205" y2="205" :id="'alert-indicator' + id_tank" />

            <line stroke="LightGrey" stroke-width="3" x1="11" x2="18" y1="15" y2="15" />
            <line stroke="LightGrey" stroke-width="3" x1="11" x2="18" y1="25" y2="25" />
            <line stroke="LightGrey" stroke-width="3" x1="11" x2="18" y1="35" y2="35" />
            <line stroke="LightGrey" stroke-width="3" x1="11" x2="18" y1="45" y2="45" />

            <line stroke="LightGrey" stroke-width="3" x1="11" x2="27" y1="55" y2="55" />

            <line stroke="LightGrey" stroke-width="3" x1="11" x2="18" y1="65" y2="65" />
            <line stroke="LightGrey" stroke-width="3" x1="11" x2="18" y1="75" y2="75" />
            <line stroke="LightGrey" stroke-width="3" x1="11" x2="18" y1="85" y2="85" />
            <line stroke="LightGrey" stroke-width="3" x1="11" x2="18" y1="95" y2="95" />

            <line stroke="LightGrey" stroke-width="3" x1="11" x2="27" y1="105" y2="105" />

            <line stroke="LightGrey" stroke-width="3" x1="11" x2="18" y1="115" y2="115" />
            <line stroke="LightGrey" stroke-width="3" x1="11" x2="18" y1="125" y2="125" />
            <line stroke="LightGrey" stroke-width="3" x1="11" x2="18" y1="135" y2="135" />
            <line stroke="LightGrey" stroke-width="3" x1="11" x2="18" y1="145" y2="145" />

            <line stroke="LightGrey" stroke-width="3" x1="11" x2="27" y1="155" y2="155" />

            <line stroke="LightGrey" stroke-width="3" x1="11" x2="18" y1="165" y2="165" />
            <line stroke="LightGrey" stroke-width="3" x1="11" x2="18" y1="175" y2="175" />
            <line stroke="LightGrey" stroke-width="3" x1="11" x2="18" y1="185" y2="185" />
            <line stroke="LightGrey" stroke-width="3" x1="11" x2="18" y1="195" y2="195" />

            <line stroke="LightGrey" stroke-width="3" x1="11" x2="27" y1="204" y2="204" />
        </svg>
    </div>
</template>

<script>
export default {
    name: 'HighGraduatedLiquidIndicatorSvg',
    props: {
        size: {
            type: Number,
            required: true,
        },
        id_tank: {
            type: Number,
            default: 0,
        },
    },
}
</script>
