<template>
    <div id="display-badge-alert-component">
        <v-btn
            :class="(alert.triggered && !alert.acquitted ? 'bounce-btn' : '')"
            :color="
                alert.acquitted
                    ? 'grey'
                    : alert.triggered
                    ? alert.configuration_appearance.color_triggered
                    : alert.configuration_appearance.color_untriggered
            "
            elevation="0"
            fab
            small
        >
            <v-icon color="white">
                mdi-{{ alert.triggered ? alert.configuration_appearance.icon_triggered : alert.configuration_appearance.icon_untriggered }}
            </v-icon>
        </v-btn>
    </div>
</template>

<script>
export default {
    name: 'display-badge-alert-component',
    props: {
        alert: {
            required: true
        }
    }
}
</script>