<template>
    <div id="dialog-acquit-alert-component">
        <v-dialog v-model="dialog_acquit_alert" max-width="500px">
            <v-card>
                <v-card-title>{{ $t('list_tank_equipmement_component.alert') }}</v-card-title>

                <v-card-text>
                    <v-row class="ma-0">
                        <v-checkbox v-model="alert.acquitted" color="primary">
                            <template v-slot:label>
                                <span>{{ $t('list_tank_equipmement_component.acknowledge_alert') }}</span>
                            </template>
                        </v-checkbox>

                        <v-tooltip max-width="250px" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon class="ml-2" color="primary" v-bind="attrs" v-on="on">mdi-information</v-icon>
                            </template>
                            <span class="white--text">
                                {{ $t('list_tank_equipmement_component.acknowledge_means_no_alert_sent') }}
                            </span>
                        </v-tooltip>
                    </v-row>

                    <v-divider class="mb-4" />

                    <p class="font-color-medium font-weight-bold font-14">{{ $t('list_tank_equipmement_component.event') }}</p>

                    <template v-if="!loading_events">
                        <template v-if="isEvents > 0">
                            <v-row v-for="event in events" :key="event.id_alert_event" class="ma-0 my-2">
                                <template v-if="display_acquit_alert_actions[event.message]">
                                    <v-icon class="mr-2">mdi-{{ display_acquit_alert_actions[event.message].icon }} </v-icon>
                                    <span>
                                        {{ $t('list_tank_equipmement_component.alert') }}
                                        {{ display_acquit_alert_actions[event.message].message }} {{ moment(event.date_event).from() }}
                                    </span>
                                    <span v-if="display_acquit_alert_actions[event.message].by">
                                        &#8239; {{ $t('list_tank_equipmement_component.by') }}
                                        {{ event.user.first_name + ' ' + event.user.last_name }}
                                    </span>
                                </template>
                            </v-row>
                        </template>

                        <template v-else>
                            <span>
                                {{ $t('list_tank_equipmement_component.no_recent_events') }}
                            </span>
                        </template>
                    </template>

                    <v-progress-linear
                        :color="loading_events ? 'primary' : '#939598'"
                        :indeterminate="loading_events"
                        class="my-4"
                        height="1"
                    />

                    <span
                        v-if="userHasAccess('BASE', this.role_edit)"
                        class="text-uppercase secondary--text font-16 text-decoration-underline font-weight-bold pointer"
                        @click="openDialogUpdateAlert()"
                    >
                        {{ $t('list_tank_equipmement_component.access_configuration') }}
                    </span>
                </v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn text @click="dialog_acquit_alert = false">
                        <span class="font-color-light font-weight-bold">{{ $t('global.cancel') }}</span>
                    </v-btn>

                    <v-btn :loading="loading" class="btn-secondary-loader" color="secondary" text @click="prepareUpdateAcquitAlert()">
                        <span class="secondary--text font-weight-bold">
                            {{ $t('global.validate') }}
                        </span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <dialog-update-alert-component
            ref="DialogUpdateAlertComponent"
            v-on:refresh:alert="$emit('refresh:alert')"
        />
    </div>
</template>

<script>
import DialogUpdateAlertComponent from '@/components/Alert/DialogUpdateAlertComponent.vue'
import AlertRepository from '@/repositories/AlertRepository'

export default {
    name: 'DialogAcquitAlertComponent',
    components: { DialogUpdateAlertComponent },
    data() {
        return {
            dialog_acquit_alert: false,
            loading: false,
            alert: {},
            loading_events: false,
            events: [],
            display_acquit_alert_actions: {
                alert_event_untriggered: {
                    icon: '',
                    message: '',
                    by: false,
                },
                alert_event_triggered: {
                    icon: 'alert-circle-outline',
                    message: this.$t('list_tank_equipmement_component.triggered'),
                    by: false,
                },
                alert_event_disclaimer: {
                    icon: 'close-circle-multiple-outline',
                    message: this.$t('list_tank_equipmement_component.revoked'),
                    by: true,
                },
                alert_event_acquit: {
                    icon: 'alert-circle-check-outline',
                    message: this.$t('list_tank_equipmement_component.acquitted'),
                    by: true,
                },
            },
        }
    },
    computed: {
        isEvents() {
            return (
                this.events.filter(
                    (event) =>
                        event.message === 'alert_event_untriggered' ||
                        event.message === 'alert_event_triggered' ||
                        event.message === 'alert_event_disclaimer' ||
                        event.message === 'alert_event_acquit'
                )?.length > 0
            )
        },
    },
    methods: {
        openDialogAcquitAlert(alert) {
            this.alert = JSON.parse(JSON.stringify(alert))
            this.fetchEvents()
            this.dialog_acquit_alert = true
        },
        fetchEvents() {
            this.loading_events = true
            AlertRepository.getAlertEvents(this.alert.id_alert)
                .then((success) => {
                    this.events = success.data.data.sort((a, b) => {
                        if (this.moment(a.date_event).valueOf() < this.moment(b.date_event).valueOf()) return 1
                        if (this.moment(a.date_event).valueOf() > this.moment(b.date_event).valueOf()) return -1
                        return 0
                    })
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading_events = false
                })
        },
        prepareUpdateAcquitAlert() {
            this.loading = true
            if (this.alert.acquitted) {
                AlertRepository.acquittedAlert(this.alert.id_alert)
                    .then(() => {
                        this.showSnackbar('success', this.$t('list_tank_equipmement_component.alert_acknowledged'))
                        this.$emit('refresh:alert')
                    })
                    .catch((error) => {
                        this.manageError(error)
                    })
                    .finally(() => {
                        this.loading = false
                        this.dialog_acquit_alert = false
                    })
            } else {
                AlertRepository.unAcquittedAlert(this.alert.id_alert)
                    .then(() => {
                        this.showSnackbar('success', this.$t('list_tank_equipmement_component.alert_revoked'))
                        this.$emit('refresh:alert')
                    })
                    .catch((error) => {
                        this.manageError(error)
                    })
                    .finally(() => {
                        this.loading = false
                        this.dialog_acquit_alert = false
                    })
            }
        },
        openDialogUpdateAlert() {
            this.$refs.DialogUpdateAlertComponent.openDialogEditAlert(this.alert)
        },
    },
}
</script>