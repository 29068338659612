var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"asset-card-alerts-component"}},[_c('v-row',{staticClass:"gap-1 line-alert",attrs:{"justify":"end"}},[(!_vm.loading_alerts)?[_c('div',{staticClass:"order-tooltip-wrapper",attrs:{"id":"order-tooltip-wrapper","data-cy":"order-tooltip-wrapper"}},[(
                        _vm.$store.getters['orders_cockpit/orders_cockpit_pending_validation'].filter(
                            (order) => order.asset.id_asset === _vm.asset.id_asset
                        ).length === 1
                    )?_c('v-btn',{staticClass:"bounce-btn mr-1",attrs:{"color":"error","data-cy":"badge-orders-cockpit-pending-validation","elevation":"0","fab":"","small":""}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-tanker-truck")])],1):_vm._e(),_c('div',{staticClass:"order-tooltip-content",attrs:{"id":"order-tooltip-content","data-cy":"order-tooltip-content"}},[_c('span',{staticClass:"font-weight-bold secondary--text"},[_vm._v(" "+_vm._s(_vm.$t('list_tank_equipmement_component.have_order_proposal_to_validate'))+" ")]),_c('v-btn',{staticClass:"font-weight-bold mt-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push('orders_cockpit')}}},[_c('span',{staticClass:"secondary--text"},[_vm._v(_vm._s(_vm.$t('list_tank_equipmement_component.access')))])]),_c('div',{staticClass:"order-tooltip-content-arrow",attrs:{"id":"order-tooltip-content-arrow"}})],1)],1),_vm._l((_vm.alerts
                    .slice(0, 6)
                    .filter((alert_filter) =>
                        alert_filter.user_alert_preference ? alert_filter.user_alert_preference.notification_badge : false
                    )
                    .sort((a, b) => Number(b.triggered) - Number(a.triggered))),function(alert){return _c('badge-alert-component',{key:alert.id_alert,attrs:{"alert":alert},on:{"refresh:alert":_vm.prepareFetchAlerts}})}),(_vm.alerts.length > 6)?_c('v-btn',{attrs:{"color":"accent pr-1","elevation":"0","fab":"","small":""}},[_c('span',{staticClass:"font-20"},[_vm._v("+6")])]):_vm._e()]:_c('v-btn',{attrs:{"elevation":"1","fab":"","loading":"","small":""}})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }