<template>
    <div id="asset-card-alerts-component">
        <v-row class="gap-1 line-alert" justify="end">
            <template v-if="!loading_alerts">
                <div id="order-tooltip-wrapper" class="order-tooltip-wrapper" data-cy="order-tooltip-wrapper">
                    <v-btn
                        v-if="
                            $store.getters['orders_cockpit/orders_cockpit_pending_validation'].filter(
                                (order) => order.asset.id_asset === asset.id_asset
                            ).length === 1
                        "
                        class="bounce-btn mr-1"
                        color="error"
                        data-cy="badge-orders-cockpit-pending-validation"
                        elevation="0"
                        fab
                        small
                    >
                        <v-icon color="white">mdi-tanker-truck</v-icon>
                    </v-btn>

                    <div id="order-tooltip-content" class="order-tooltip-content" data-cy="order-tooltip-content">
                        <span class="font-weight-bold secondary--text">
                            {{ $t('list_tank_equipmement_component.have_order_proposal_to_validate') }}
                        </span>

                        <v-btn class="font-weight-bold mt-2" color="primary" @click="$router.push('orders_cockpit')">
                            <span class="secondary--text">{{ $t('list_tank_equipmement_component.access') }}</span>
                        </v-btn>

                        <div id="order-tooltip-content-arrow" class="order-tooltip-content-arrow"></div>
                    </div>
                </div>

                <badge-alert-component
                    v-for="alert in alerts
                        .slice(0, 6)
                        .filter((alert_filter) =>
                            alert_filter.user_alert_preference ? alert_filter.user_alert_preference.notification_badge : false
                        )
                        .sort((a, b) => Number(b.triggered) - Number(a.triggered))"
                    :key="alert.id_alert"
                    :alert="alert"
                    v-on:refresh:alert="prepareFetchAlerts"
                />

                <v-btn v-if="alerts.length > 6" color="accent pr-1" elevation="0" fab small>
                    <span class="font-20">+6</span>
                </v-btn>
            </template>

            <v-btn v-else elevation="1" fab loading small />
        </v-row>
    </div>
</template>

<script>
import BadgeAlertComponent from '@/components/Alert/BadgeAlertComponent.vue'

export default {
    name: 'asset-card-alerts-component',
    components: {
        BadgeAlertComponent,
    },
    props: {
        asset: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            alerts: [],
            loading_alerts: false,
        }
    },
    mounted() {
        this.prepareFetchAlerts()
    },
    methods: {
        async prepareFetchAlerts() {
            this.loading_alerts = true
            this.$store
                .dispatch('alerts/fetchAlertsByAsset', this.asset)
                .then(() => {
                    this.alerts = this.$store.getters['alerts/alertsByAsset'](this.asset)
                })
                .finally(() => {
                    this.avoidTooltipOffScreen()
                    this.loading_alerts = false
                })
        },
        avoidTooltipOffScreen() {
            setTimeout(() => {
                let tooltips = document.getElementsByClassName('order-tooltip-content')
                let arrows = document.getElementsByClassName('order-tooltip-content-arrow')
                Array.prototype.forEach.call(document.getElementsByClassName('order-tooltip-wrapper'), (el, index) => {
                    //avoid error during fast navigation
                    if (this.$router.currentRoute.name === 'equipment') {
                        el.addEventListener('mouseover', (e) => {
                            if (window.innerWidth < e.pageX + 160) {
                                tooltips[index].style.left = '-85px'
                                arrows[index].style.left = '70%'
                            } else {
                                tooltips[index].style.left = '-55px'
                                arrows[index].style.left = '50%'
                            }
                        })
                    }
                })
            }, 500)
        },
    },
}
</script>

<style>
.line-alert {
    position: absolute;
    top: -13px;
    right: 20px;
}

.order-tooltip-content {
    visibility: hidden;
    position: absolute;
    background: white;
    width: 150px;
    bottom: 50px;
    left: -55px;
    padding: 10px;
    border-radius: 2px;
    text-align: center;
    filter: drop-shadow(0 0 2px rgb(0 0 0 / 20%));
    opacity: 0;
    z-index: 99;
    display: none;
}

.order-tooltip-content-arrow {
    content: ' ';
    position: absolute;
    bottom: -10px; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: white transparent transparent transparent;
}

.order-tooltip-wrapper:hover .order-tooltip-content {
    visibility: visible;
    opacity: 1;
    display: block;
}
</style>
