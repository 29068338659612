var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-fit",attrs:{"id":"display-asset-level-component"}},[(_vm.show)?_c('div',{staticClass:"position-relative w-fit",style:(`height: ${_vm.getSizeOfAsset('image_height')}px;`)},[(_vm.show_indicator)?_c('div',{style:(`
                   position: absolute; left: -30px;
                    bottom: ${_vm.getSizeOfAsset('bottom') - 5}px;
                 `)},[(_vm.getAssetDisplayOption('high_graduate'))?_c('high-graduate-liquid-indicator-svg',{attrs:{"id_tank":_vm.asset.id_asset,"size":_vm.getSizeOfAsset('liquid_height')}}):_c('graduated-liquid-indicator-svg',{attrs:{"id_tank":_vm.asset.id_asset,"size":_vm.getSizeOfAsset('liquid_height')}}),_c('div',{class:`${_vm.getIdForAsset('dash')} dash`,style:(`
                        height: ${(_vm.getAssetDisplayOption('high_graduate') ? 4 : 2) * this.scale}px;
                        width: ${(_vm.getAssetDisplayOption('high_graduate') ? 30 : 20) * this.scale}px;
                 `)})],1):_vm._e(),_c('div',[_c('img',{staticClass:"mx-auto",style:(`height: ${_vm.getSizeOfAsset('image_height')}px`),attrs:{"src":_vm.$vuetify.theme.dark
                        ? '/images/asset/dark/' + _vm.getAssetDisplayOption('image_path') + '.png'
                        : '/images/asset/light/' + _vm.getAssetDisplayOption('image_path') + '.png',"alt":"asset"}}),_c('div',{class:`${_vm.getClassForAsset('shape')} shape`,style:(`
                    height: ${_vm.getSizeOfAsset('liquid_height')}px;
                    width: ${_vm.getSizeOfAsset('liquid_width')}px;
                    bottom: ${_vm.getSizeOfAsset('bottom')}px;
                 `)},[_c('div',{class:`border-level ${_vm.getIdForAsset('border')}`}),_c('div',{class:`w-100 liquid-level absolute bottom-0 opacity-30 ${_vm.getIdForAsset('liquid')}`})])]),(_vm.show_indicator)?_c('div',{class:`${_vm.getIdForAsset('chips-asset')} chips-asset`,style:(`bottom: ${_vm.getSizeOfAsset('bottom')}px;`)},[_c('span',{class:`white--text ${_vm.is_mobile ? 'font-10' : 'font-12'}`},[_vm._v(" "+_vm._s(_vm.numberWithSpaces(Math.round(_vm.asset.actual_volume), 0))+" L ")]),_c('div',{class:`${_vm.getIdForAsset('triangle')} triangle`})]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }