<template>
    <div id="badge-alert-component">
        <div @click="openAlertDialog()">
            <display-badge-alert-component :alert="alert" />
        </div>

        <dialog-update-alert-component
            v-if="userHasAccess('BASE', this.role_edit)"
            ref="DialogUpdateAlertComponent"
            data-cy="dialog-update-alert-component"
            v-on:refresh:alert="refreshAlert"
        />

        <dialog-acquit-alert-component ref="DialogAcquitAlertComponent" v-on:refresh:alert="refreshAlert" />
    </div>
</template>

<script>
import DisplayBadgeAlertComponent from '@/components/Alert/DisplayBadgeAlertComponent.vue'
import DialogUpdateAlertComponent from '@/components/Alert/DialogUpdateAlertComponent.vue'
import { EmptyAlert } from '@/models/IAlert'
import DialogAcquitAlertComponent from '@/components/Alert/DialogAcquitAlertComponent.vue'

export default {
    name: 'BadgeAlertComponent',
    components: { DialogAcquitAlertComponent, DisplayBadgeAlertComponent, DialogUpdateAlertComponent },
    props: {
        alert: {
            type: Object,
            required: true,
            default: EmptyAlert,
        },
    },
    methods: {
        openAlertDialog() {
            if (this.alert.triggered) {
                this.$refs.DialogAcquitAlertComponent.openDialogAcquitAlert(this.alert)
            } else {
                this.$refs.DialogUpdateAlertComponent.openDialogEditAlert(this.alert)
            }
        },
        refreshAlert() {
            this.$emit('refresh:alert')
        },
    },
}
</script>