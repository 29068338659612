<template>
    <div id="graduate-liquid-indicator-svg">
        <svg :style="'height:' + size + 'px'" height="106" viewBox="0 0 30 106" xmlns="http://www.w3.org/2000/svg">
            <line stroke="LightGrey" stroke-width="2" x1="9" x2="20" y1="5" y2="5" />

            <line stroke="LightGrey" stroke-width="6" x1="3" x2="3" y1="5" y2="105" />

            <line stroke="red" stroke-width="6" x1="3" x2="3" y1="105" y2="105" :id="'alert-indicator' + id_tank" />

            <line stroke="LightGrey" stroke-width="2" x1="9" x2="13" y1="10" y2="10" />
            <line stroke="LightGrey" stroke-width="2" x1="9" x2="13" y1="15" y2="15" />
            <line stroke="LightGrey" stroke-width="2" x1="9" x2="13" y1="20" y2="20" />
            <line stroke="LightGrey" stroke-width="2" x1="9" x2="13" y1="25" y2="25" />

            <line stroke="LightGrey" stroke-width="2" x1="9" x2="20" y1="30" y2="30" />

            <line stroke="LightGrey" stroke-width="2" x1="9" x2="13" y1="35" y2="35" />
            <line stroke="LightGrey" stroke-width="2" x1="9" x2="13" y1="40" y2="40" />
            <line stroke="LightGrey" stroke-width="2" x1="9" x2="13" y1="45" y2="45" />
            <line stroke="LightGrey" stroke-width="2" x1="9" x2="13" y1="50" y2="50" />

            <line stroke="LightGrey" stroke-width="2" x1="9" x2="20" y1="55" y2="55" />

            <line stroke="LightGrey" stroke-width="2" x1="9" x2="13" y1="60" y2="60" />
            <line stroke="LightGrey" stroke-width="2" x1="9" x2="13" y1="65" y2="65" />
            <line stroke="LightGrey" stroke-width="2" x1="9" x2="13" y1="70" y2="70" />
            <line stroke="LightGrey" stroke-width="2" x1="9" x2="13" y1="75" y2="75" />

            <line stroke="LightGrey" stroke-width="2" x1="9" x2="20" y1="80" y2="80" />

            <line stroke="LightGrey" stroke-width="2" x1="9" x2="13" y1="85" y2="85" />
            <line stroke="LightGrey" stroke-width="2" x1="9" x2="13" y1="90" y2="90" />
            <line stroke="LightGrey" stroke-width="1" x1="9" x2="13" y1="95" y2="95" />
            <line stroke="LightGrey" stroke-width="2" x1="9" x2="13" y1="100" y2="100" />

            <line stroke="LightGrey" stroke-width="2" x1="9" x2="20" y1="105" y2="105" />
        </svg>
    </div>
</template>

<script>
export default {
    name: 'GraduatedLiquidIndicatorSvg',
    props: {
        size: {
            type: Number,
            required: true,
        },
        id_tank: {
            type: Number,
            default: 0,
        },
    },
}
</script>
