<template>
    <div id="display-asset-level-component" class="w-fit">
        <div v-if="show" :style="`height: ${getSizeOfAsset('image_height')}px;`" class="position-relative w-fit">
            <div
                v-if="show_indicator"
                :style="`
                       position: absolute; left: -30px;
                        bottom: ${getSizeOfAsset('bottom') - 5}px;
                     `"
            >
                <high-graduate-liquid-indicator-svg
                    v-if="getAssetDisplayOption('high_graduate')"
                    :id_tank="asset.id_asset"
                    :size="getSizeOfAsset('liquid_height')"
                />
                <graduated-liquid-indicator-svg v-else :id_tank="asset.id_asset" :size="getSizeOfAsset('liquid_height')" />
                <div
                    :class="`${getIdForAsset('dash')} dash`"
                    :style="`
                            height: ${(getAssetDisplayOption('high_graduate') ? 4 : 2) * this.scale}px;
                            width: ${(getAssetDisplayOption('high_graduate') ? 30 : 20) * this.scale}px;
                     `"
                />
            </div>

            <div>
                <img
                    :src="
                        $vuetify.theme.dark
                            ? '/images/asset/dark/' + getAssetDisplayOption('image_path') + '.png'
                            : '/images/asset/light/' + getAssetDisplayOption('image_path') + '.png'
                    "
                    :style="`height: ${getSizeOfAsset('image_height')}px`"
                    alt="asset"
                    class="mx-auto"
                />

                <div
                    :class="`${getClassForAsset('shape')} shape`"
                    :style="`
                        height: ${getSizeOfAsset('liquid_height')}px;
                        width: ${getSizeOfAsset('liquid_width')}px;
                        bottom: ${getSizeOfAsset('bottom')}px;
                     `"
                >
                    <div :class="`border-level ${getIdForAsset('border')}`" />
                    <div :class="`w-100 liquid-level absolute bottom-0 opacity-30 ${getIdForAsset('liquid')}`" />
                </div>
            </div>

            <div
                v-if="show_indicator"
                :class="`${getIdForAsset('chips-asset')} chips-asset`"
                :style="`bottom: ${getSizeOfAsset('bottom')}px;`"
            >
                <span :class="`white--text ${is_mobile ? 'font-10' : 'font-12'}`">
                    {{ numberWithSpaces(Math.round(asset.actual_volume), 0) }} L
                </span>
                <div :class="`${getIdForAsset('triangle')} triangle`"/>
            </div>
        </div>
    </div>
</template>

<script>
import GraduatedLiquidIndicatorSvg from '@/components/Svg/GraduatedLiquidIndicatorSvg.vue'
import HighGraduateLiquidIndicatorSvg from '@/components/Svg/HighGraduateLiquidIndicatorSvg.vue'
import { nextTick } from 'vue'

export default {
    name: 'DisplayAssetLevelComponent',
    components: { HighGraduateLiquidIndicatorSvg, GraduatedLiquidIndicatorSvg },
    props: {
        asset: {
            type: Object,
            required: true,
        },
        scale: {
            type: Number,
            required: false,
            default: 1,
        },
        colorless: {
            type: Boolean,
            required: false,
            default: false,
        },
        show_indicator: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            show: false,
            models: {
                0: {
                    image_path: 'tank',
                    image_height: 100,
                    liquid_height: 78,
                    liquid_width: 170,
                    bottom: 12,
                    high_graduate: false,
                },
                1: {
                    image_path: 'tank_rectangular',
                    image_height: 100,
                    liquid_height: 78,
                    liquid_width: 170,
                    bottom: 12,
                    high_graduate: false,
                },
                2: {
                    image_path: 'tank',
                    image_height: 100,
                    liquid_height: 78,
                    liquid_width: 170,
                    bottom: 12,
                    high_graduate: false,
                },
                3: {
                    image_path: 'tank_cylindrical',
                    image_height: 160,
                    liquid_height: 148,
                    liquid_width: 66,
                    bottom: 8,
                    high_graduate: true,
                },
                5: {
                    image_path: 'IBC',
                    image_height: 120,
                    liquid_height: 102,
                    liquid_width: 114,
                    bottom: 10,
                    high_graduate: false,
                },
                7: {
                    image_path: 'tower_rectangular',
                    image_height: 160,
                    liquid_height: 155,
                    liquid_width: 70,
                    bottom: 3,
                    high_graduate: true,
                },
                8: {
                    image_path: 'tower_cylindrical',
                    image_height: 160,
                    liquid_height: 155,
                    liquid_width: 70,
                    bottom: 3,
                    high_graduate: true,
                },
                9: {
                    image_path: 'silo',
                    image_height: 160,
                    liquid_height: 154,
                    liquid_width: 70,
                    bottom: 4,
                    high_graduate: true,
                },
                10: {
                    image_path: 'silo_rectangular',
                    image_height: 160,
                    liquid_height: 154,
                    liquid_width: 70,
                    bottom: 4,
                    high_graduate: true,
                },
            },
        }
    },
    created() {
        this.findModelToDisplay()
    },
    methods: {
        findModelToDisplay() {
            this.show = false
            this.image_asset = this.models[this.asset.type].image_path
            setTimeout(() => {
                this.setHeightLiquid()
            }, 50)
            this.show = true
        },
        getAssetDisplayOption(data) {
            return this.models[this.asset.type][data]
        },
        getSizeOfAsset(data) {
            return this.models[this.asset.type][data] * this.scale
        },
        getClassForAsset(prefix) {
            return `${prefix}-${this.asset.type}`
        },
        getIdForAsset(prefix) {
            return `${this.scale}-${prefix}-${this.asset.id_asset}`
        },
        getEquivalentPercentLevel() {
            return ((this.asset.actual_volume * 100) / this.asset.volume_total / 100) * this.getSizeOfAsset('liquid_height')
        },
        setHeightLiquid() {
            if(this.asset.is_subscription_device_active) {
                let height = this.getEquivalentPercentLevel()
                let color = this.getColorLiquid()
                Array.prototype.forEach.call(document.getElementsByClassName(this.getIdForAsset('liquid')), function (liquid) {
                    liquid.style.height = height + 'px'
                    liquid.style.background = color
                })
                Array.prototype.forEach.call(document.getElementsByClassName(this.getIdForAsset('border')), function (border) {
                    border.style.bottom = height - 2 + 'px'
                    border.style.background = color
                })
                Array.prototype.forEach.call(document.getElementsByClassName(this.getIdForAsset('dash')), function (dash) {
                    dash.style.bottom = height + 4 + 'px'
                    dash.style.background = color
                })
                Array.prototype.forEach.call(document.getElementsByClassName(this.getIdForAsset('chips-asset')), function (chips) {
                    chips.style.bottom = height + 2 + 'px'
                    chips.style.background = color
                })
                Array.prototype.forEach.call(document.getElementsByClassName(this.getIdForAsset('triangle')), function (chips) {
                    chips.style.borderRight = '7px solid ' + color
                })
            }
        },
        getColorLiquid() {
            return this.colorless ? '#9399a3' : this.asset.asset_liquid ? this.asset.asset_liquid.color : this.$vuetify.theme.themes.light.primary
        },
    },
    watch: {
        colorless: {
            handler() {
                this.setHeightLiquid()
            },
        },
        is_mobile: {
            handler() {
                nextTick(() => {
                    this.setHeightLiquid()
                })
            },
        }
    },
}
</script>

<style>
.liquid-level,
.border-level,
.dash,
.chips-asset {
    transition: height, bottom, opacity !important;
    transition-duration: 0.7s !important;
    transition-timing-function: ease-in-out !important;
}

.liquid-level {
    height: 0;
    width: 100%;
    position: absolute;
    bottom: 0;
    opacity: 0.3;
}

.border-level {
    bottom: -4px;
    width: 100%;
    position: absolute;
    height: 2px;
}

.dash {
    position: absolute;
}

.chips-asset {
    background: red;
    width: fit-content;
    padding-inline: 4px;
    border-radius: 5px;
    position: absolute;
    font-size: 12px;
    right: -70px;
    white-space: nowrap;
}

.triangle {
    width: 0px;
    height: 0px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid blue;
    position: absolute;
    left: -5px;
    top: 6px;
}

.shape {
    position: absolute;
    left: 50%;
    translate: -50%;
    overflow: hidden;
}

.shape-0,
.shape-2 {
    border-radius: 500px;
}

.shape-1 {
    border-radius: 2%;
}

.shape-3 {
    border-radius: 20%;
}

.shape-5 {
    border-radius: 5px;
}

.shape-7 {
    clip-path: polygon(32% 0, 68% 0%, 100% 10%, 100% 98%, 98% 100%, 2% 100%, 0 98%, 0 10%);
}

.shape-8 {
    border-radius: 23% 23% 5% 5%;
}

.shape-9 {
    clip-path: polygon(32% 0, 68% 0%, 100% 12%, 100% 78%, 68% 100%, 32% 100%, 0 78%, 0 12%);
    border-radius: 30%;
}

.shape-10 {
    clip-path: polygon(32% 0, 68% 0%, 100% 12%, 100% 78%, 68% 100%, 32% 100%, 0 78%, 0 12%);
}
</style>
